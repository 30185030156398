import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import LiveChat from "../components/Home/livechat";


const Verticals = ({ data, location, pageContext }) => {
  const SEOdata = data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.pageData.edges[0].node.frontmatter?.Hreflang


  const posts = data.featurePost.group
  const { industryCurrentPage, industryNumPages } = pageContext
  const isFirst = industryCurrentPage === 1
  const isLast = industryCurrentPage === industryNumPages
  const prevPage = industryCurrentPage - 1 === 1 ? "" : (industryCurrentPage - 1).toString()
  const nextPage = (industryCurrentPage + 1).toString()


  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Verticals"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        
      />
      {/* banner content */}

      <section className="pt-1-25 ">
        <div className="container mx-auto">
          <div className="pb-35 lg:pb-46 md:w-4/5 lg:w-3/4 xl:w-3/5 mx-auto text-center relative">
            <h2 className="pb-3-1 lg:pb-3-2 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-cyan text-center">
              Industries Served<br></br>
              <span className="text-term-primary">
                The sky is the limit
              </span>
            </h2>
            <p className="px-4 lg:px-0 xl:px-10 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-center about-text">
              From mom-and-pop shops and emerging e-commerce brands to thriving small businesses and enterprise organizations, we serve dedicated clients at every stage of their journey.
            </p>
          </div>

          {/* industries listing */}

          <div className="pb-6-6 lg:pb-60 relative">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6-6 gap-y-35 md:gap-y-16">
              {posts &&
                posts.map(({ edges: edge }, index) => (
                  (edge.map(({ node: post }, i) => (
                    <div key={i} className="verticals-items">
                      <div className="w-full  xl:h-2-00">
                        <GatsbyImage image={getImage(post.frontmatter.featured_image.image)} alt={post.frontmatter.featured_image.alt} className="w-full h-full" />
                      </div>
                      <div className="mt-3-3 lg:mt-5 xl:mt-49">
                        <h3 className="pb-2-1 text-rp1 lg:text-h3 text-term-primary font-poppings-bold">
                          {post.frontmatter.title}
                        </h3>
                        <p className="pb-3 lg:pb-6 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                          {post.frontmatter.excerpt}
                        </p>
                        {/* <Link to="#" className={`arrowhlink animate-arrow-${i} text-h6 lg:text-h5 font-poppings-bold text-cyan flex items-center tech-view-detail delay-150 duration-300 ease-in-out`} >
                          <span className="mr-1-2"> View Details </span>
                          <img src={Arrow} alt={"Arrow"} />
                        </Link> */}
                      </div>
                    </div>
                  )))
                ))}
            </div>
          </div>

          {/* pagination */}
          <div className="pt-45 md:pt-84 pb-87 md:pb-1-15 blog-pagination">
            <ul className="pagination">
              {!isFirst && (
                <li className="page-next">
                  <Link className="next" to={`/verticals/${prevPage}`}>
                    <svg
                      className="transform rotate-180 stroke-current text-dark-grey"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
                    </svg>
                  </Link>
                </li>
              )}
              {industryNumPages > 1 &&
                (Array.from({ length: industryNumPages }, (_, i) => (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="page-number"
                  >
                    <Link
                      to={`/verticals/${i === 0 ? "" : i + 1}`}
                      style={{
                        color:
                          i + 1 === industryCurrentPage ? "#ffffff" : "var(--text-term-secondary)",
                        background: i + 1 === industryCurrentPage ? "#00AEEF" : "",
                      }}>
                      {i + 1}
                    </Link>
                  </li>
                )))}
              {!isLast && (
                <li className="page-next">
                  <Link className="next" to={`/verticals/${nextPage}`}>
                    <svg
                      className="stroke-current text-dark-grey"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
                    </svg>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>

      <LiveChat />
    </Layout>
  )
}
export default Verticals;

export const pageQuery = graphql`
query IndustriesPageQuery($skip: Int!, $limit: Int!) {
  featurePost: allMarkdownRemark(
    sort: { order: ASC, fields: [frontmatter___date] }
    filter: { frontmatter: { templateKey: { eq: "industry" } } }
    limit: $limit
    skip: $skip
  ) {
    group(field: frontmatter___industry_type) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featured_image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            date(formatString: "DD MMM, YYYY")
          }
        }
      }
    }
  }
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/pages/verticals.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`;